import Glide from '@glidejs/glide';

const locationsSliders = document.querySelectorAll('.block--type-locations');
locationsSliders.forEach((item) => {
  new Glide(item, {
    perView: 4,
    gap: 38,
    peek: {
        before: 0,
        after: 120
    },
    breakpoints: {
        1250: {
            perView: 3,
            gap: 38,
            peek: {
                before: 0,
                after: 120
            },
        },
        1000: {
            perView: 3,
            gap: 20,
            peek: {
                before: 0,
                after: 120
            },
        },
        750: {
            perView: 2,
            gap: 20,
            peek: {
                before: 0,
                after: 120
            },
        },
        650: {
            perView: 1,
            gap: 15,
            peek: {
                before: 0,
                after: 120
            },
        },
    },
  }).mount();
});

const newsSliders = document.querySelectorAll('.block--type-news, .block--type-cards');
newsSliders.forEach((item) => {
  new Glide(item, {
    perView: 4,
    gap: 38,
    dragDistance: false,
    peek: {
        before: 0,
        after: 0
    },
    breakpoints: {
        1400: {
            dragDistance: false,
            perView: 2,
            gap: 30,
            peek: {
                before: 0,
                after: 120
            },
        },
        650: {
            perView: 1,
            gap: 20,
            peek: {
                before: 0,
                after: 80
            },
        },
    },
  }).mount();
});
