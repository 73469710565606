<template>
    <div>
        <div v-if="filter == 'true' || filter">
            <h3>Filter by</h3>
            <div class="row pt-6">
                <div class="col-xs-12 col-md-4">
                    <select v-model="filters.level">
                        <option value="" selected="selected">All levels</option>            
                        <option value="1">Level 1</option>
                        <option value="2">Level 2</option>
                        <option value="3">Level 3</option>
                        <option value="4">Level 4</option>
                        <option value="5">Level 5</option>
                        <option value="6">Level 6</option>
                        <option value="7">Level 7</option>
                    </select>
                </div>
                <div class="col-xs-12 col-md-4">
                    <select v-model="filters.type">
                        <option value="" selected="selected">All types</option>   
                        <option value="apprenticeships">Apprenticeships</option>                         
                        <option value="learner-loans">Learner Loans</option>
                        <option value="short-courses">Short Courses</option>
                        <option value="traineeships">Traineeships</option>
                    </select>
                </div>
                <div class="col-xs-12 col-md-4">
                    <select v-model="filters.industry">
                        <option value="" selected="selected">All Industries</option>   
                        <option v-for="(industry, index) in industries" :key="index" :value="industry.id" v-html="industry.name">{{industry.name}}</option>
                    </select>
                </div>
            </div>
            
            
        </div>
        <div style="overflow-x:scroll">
        <table class="course-list">
            <thead>
                <tr class="course-list__headers">
                    <th colspan="1">
                        <h4>Course Title</h4>
                    </th>
                    <th colspan="1">
                        <h4>Level</h4>
                    </th>
                    <th colspan="1">
                        <h4>Course Type</h4>
                    </th>
                    <th colspan="1">
                        <h4>Industry</h4>
                    </th>
                    <th>
                    </th>
                </tr>
            </thead>
            <tbody v-if="Object.keys(courses).length == 0 || loadingState" >
                <tr v-if="loadingState" :key="-2" class="course-list__item-single">
                    <td>
                        <span class="course-list__match-height">Loading courses</span>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                </tr>
                <tr v-if="Object.keys(courses).length == 0 && loadingState == false" :key="-1" class="course-list__item-single">
                    <td>
                        <span class="course-list__match-height">No courses found</span>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                </tr>
            </tbody>
            <tbody is="transition-group" name="rowtrans" class="course-list_list">
                <tr v-for="(course, index) in courses" :key="index" class="course-list__item" v-bind:data-index="index">
                    <td>
                        <div>
                            <strong v-text="course.title">Course Title</strong>
                        </div>
                    </td>
                    <td>
                        <label>Level: </label>
                        <div v-text="course.level">Level</div>
                    </td>
                    <td>
                        <label>Type: </label>
                        
                        <div v-text="course.type">Type</div>
                    </td>
                    <td>
                        <label>Industry: </label>                        
                        <div v-text="course.industry">Digital</div>
                    </td>		
                    <td>
                        <div>
                            <a class="button button--type-primary" :href="course.permalink">
                                View Course
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        </div>
    </div>
</template>

<script>
import 'jquery'
import axios from 'axios';
import { setTimeout } from 'timers';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";

export default {
    computed: {
        level() {
            return this.filters.level;
        },

        type() {
            return this.filters.type;
        },

        industry() {
            return this.filters.industry;
        }
    },

    watch: {
        level () {
            this.$router.replace({query: { industry: this.filters.industry, type: this.filters.type, level: this.filters.level}})
            this.filterCourses();
        },

        type () {
            this.$router.replace({query: { industry: this.filters.industry, type: this.filters.type, level: this.filters.level }})            
            this.filterCourses();
        },
        
        industry () {
            this.$router.replace({query: { industry: this.filters.industry, type: this.filters.type, level: this.filters.level }})                        
            this.filterCourses();
        },
    },
    props: {
        filter: {
            default: true,
        },
    },

    data() {
        return {
            filters: {
                industry: '',                
                level: '',
                type: ''
            },
            industries: {},
            courses: {},
            loadingState: true,
            errors: {},
        }
    },

    beforeMount() {
        this.fetchIndustries();        
    },

    mounted() {
        this.init();
    },

    methods: {
        init(){
            let vm = this;

            if(vm.filter) {
                vm.filterCourses()
            }else{
                if(vm.$route.query.type){
                vm.filters.type = vm.$route.query.type
                }

                if(vm.$route.query.level){
                vm.filters.level = vm.$route.query.level
                }

                if(vm.$route.query.industry){
                vm.filters.industry = vm.$route.query.industry
                }
                axios.get('/wp-json/sne/v1/courses')
                .then(function (response) {
                    setTimeout(function(){
                        vm.loadingState = false;
                        if(response.data == null) {
                            vm.courses = {}
                        }else{
                            vm.courses = response.data
                        }
                    }, 1000)
                    
                })
                .catch(function (error) {
                    vm.errors = error;
                });
            }
        },

        fetchIndustries () {
            let vm = this;        
            
            axios.get('/wp-json/sne/v1/industries')
            .then(function (response) {
                vm.industries = response.data;
            })
            .catch(function (error) {
                vm.errors = error;
            });
        },

        filterCourses () {
            let vm = this;        
            vm.courses = {}            
            vm.loadingState = true;

            if(vm.$route.query.type){
                vm.filters.type = vm.$route.query.type
            }

            if(vm.$route.query.level){
                vm.filters.level = vm.$route.query.level
            }

            if(vm.$route.query.industry){
                vm.filters.industry = vm.$route.query.industry
            }

            axios.get('/wp-json/sne/v1/courses?level='+vm.filters.level+'&type='+vm.filters.type+'&industry='+vm.filters.industry)
            .then(function (response) {

                setTimeout(function(){
                    vm.loadingState = false;
                    
                    if(response.data == null) {
                        vm.courses = {}
                    }else{
                        vm.courses = response.data
                    }
                    
                },1000)
                
            })
            .catch(function (error) {
                vm.errors = error;
            });
        },

        // beforeEnter(el) {
        //     console.log(el)
        //     var children = $(el).children('td').children('div');
            
        //     TweenMax.set(children, {autoAlpha:0, maxHeight: 0, paddingTop: 0, paddingBottom:0, paddingLeft:40, paddingRight:40})            
        //     $( ".course-list_list" ).append( el );    
        // },
        // enter(el, done) {
        //     var delay = el.dataset.index * 150
        //     var children = $(el).children('td').children('div');
            
        //     setTimeout(function(){
        //         TweenMax.to(children, 1, {maxHeight: 'none', autoAlpha:1, paddingTop: 30, paddingBottom:30, paddingLeft:40, paddingRight:40, ease:Power3.easeInOut, onComplete: function() {done}})
        //     }, delay)
        // },
        // leave(el, done) {
        //     console.log(el);
        //     var children = $(el).children('td').children('div');
        //     TweenMax.to(children, 0, {autoAlpha:0, maxHeight: 0 ,paddingTop: 0, paddingBottom:0, paddingLeft:40, paddingRight:40, overflow: 'hidden',  onComplete: function() {
        //         el.parentElement.removeChild(el)
        //         done
        //     }})
        // }
    }
}
</script>

