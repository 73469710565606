<template>
  <div class="container">
      <section class="vacancy-list__filters">
        <h3 class="mb-0">
          Refine results
        </h3>
        <div class="row pt-6">
            <div class="col-xs-12 col-md-4">
                <select v-model="filter.type">
                    <option value="all" selected="selected">All types</option>            
                    <option value="Apprenticeships">Apprenticeships</option>
                    <option value="Short courses">Short courses</option>
                    <option value="Learner loans">Learner loans</option>
                    <option value="Traineeships">Traineeships</option>
                </select>
            </div>
        </div>
      </section>
      <section >
        <table class="vacancy-list"  style="border-spacing: 0px 1rem;">
          <thead>
            <tr class="vacancy-list__headers">
              <th>Vacancy title</th>
              <th>Type</th>
              <th>Location</th>
              <th>-</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              class="vacancy-list__item"
              v-for="(vacancy, index) in filtered" 
              v-bind:key="index"
            >
              <td>
                <div>
                  <h4 class="mb-0">
                    <span v-text="vacancy.title"></span>
                  </h4>
                </div>
              </td>
              <td>
                <div>
                  <span v-text="vacancy.type"></span>
                </div>
              </td>
              <td>
                <div>
                  <span v-text="vacancy.region ? vacancy.region : 'Unknown' "></span>
                </div>
              </td>
              <td>
                <div>
                  <a :href="vacancy.permalink" class="button button--type-primary">View vacancy</a>
                </div>
              </td>
            </tr>
            <tr v-if="filtered.length == 0" class="vacancy-list__item-single">
              <td class="type type__align--center" colspan="4">
                <div>
                  <h4 class="mb-0 type__align--center">
                    No vacancys found
                  </h4>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
  </div>
</template>

<script>
export default {
  props: {
    vacancies: {
      default: () => {
        return {};
      },
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      filter: {
        type: 'all',
      }
    }
  },
  computed: {
    filtered() {
      const filtered = [];
      this.vacancies.forEach(vacancy => {
        if((this.filter.type == vacancy.type || this.filter.type == 'all'))
          filtered.push(vacancy);
      });
      return filtered;
    }
  }
}
</script>

<style>

</style>