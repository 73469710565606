import axios from 'axios';
import LazyLoad from "vanilla-lazyload";
import objectFitImages from 'object-fit-images';

export default {
  init() {
    $(function () { objectFitImages() });

    var aLazyLoad = new LazyLoad({ 
        /* options here */ 
    });

    $(window).resize(function() {
      // This will fie each time the window is resized:
        console.log('less than');
        $( ".menu-item-has-children > a" ).click(function(event) {
          
          if($(window).width() <= 850) {
            event.preventDefault(true);
            
            $(this).parent().toggleClass('active');
          }
        });
    }).resize();

    // JavaScript to be fired on all pages
    $( ".accordion__title" ).click(function() {
      let accordion = $(this).parent('.accordion');
      let allPanels = accordion.children('.accordion__content');
      let allTitles = accordion.children('.accordion__title');
      
      allTitles.children('h4').children('i').removeClass('fa-minus').addClass('fa-plus');
      allPanels.removeClass('active')
      allTitles.removeClass('active')

      $(this).toggleClass('active');
      $(this).next('.accordion__content').toggleClass('active');
      if($(this).hasClass('active')) {
        $(this).children('h4').children('i').addClass('fa-minus').removeClass('fa-plus');
      }else{
        $(this).children('h4').children('i').removeClass('fa-minus').addClass('fa-plus');
        
      }
    });

    $(function() {
      var nav = $('header.banner nav');
      var header = $('header.banner');      
      var button = $('.menu-btn');
      button.on('click', function(){
        header.toggleClass('bg-black');
        nav.toggleClass('active');        
        $('.menu-btn__close, .menu-btn__open').toggleClass('hidden');
      });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired


  },
};
