var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("section", { staticClass: "vacancy-list__filters" }, [
      _c("h3", { staticClass: "mb-0" }, [
        _vm._v("\n        Refine results\n      ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row pt-6" }, [
        _c("div", { staticClass: "col-xs-12 col-md-4" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter.type,
                  expression: "filter.type"
                }
              ],
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.filter,
                    "type",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "all", selected: "selected" } }, [
                _vm._v("All types")
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Apprenticeships" } }, [
                _vm._v("Apprenticeships")
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Short courses" } }, [
                _vm._v("Short courses")
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Learner loans" } }, [
                _vm._v("Learner loans")
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Traineeships" } }, [
                _vm._v("Traineeships")
              ])
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", [
      _c(
        "table",
        {
          staticClass: "vacancy-list",
          staticStyle: { "border-spacing": "0px 1rem" }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.filtered, function(vacancy, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "vacancy-list__item" },
                  [
                    _c("td", [
                      _c("div", [
                        _c("h4", { staticClass: "mb-0" }, [
                          _c("span", {
                            domProps: { textContent: _vm._s(vacancy.title) }
                          })
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("div", [
                        _c("span", {
                          domProps: { textContent: _vm._s(vacancy.type) }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("div", [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              vacancy.region ? vacancy.region : "Unknown"
                            )
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "button button--type-primary",
                            attrs: { href: vacancy.permalink }
                          },
                          [_vm._v("View vacancy")]
                        )
                      ])
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _vm.filtered.length == 0
                ? _c("tr", { staticClass: "vacancy-list__item-single" }, [
                    _vm._m(1)
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "vacancy-list__headers" }, [
        _c("th", [_vm._v("Vacancy title")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Location")]),
        _vm._v(" "),
        _c("th", [_vm._v("-")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      { staticClass: "type type__align--center", attrs: { colspan: "4" } },
      [
        _c("div", [
          _c("h4", { staticClass: "mb-0 type__align--center" }, [
            _vm._v("\n                  No vacancys found\n                ")
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }