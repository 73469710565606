import 'jquery';
import Vue from 'vue';
import axios from 'axios';
import courseList from './components/courseList.vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
if($('#course-list').length) {
    Vue.config.devtools = true;
    Vue.component('courseFilter', require('./components/courseList.vue').default)
    
    const routes = [
    ]
    
    const router = new VueRouter({
        routes,
        mode: 'history'
    })

    const courseListApp = new Vue({
        el: '#course-list',
        name: 'ourseList',    
        router,
        mounted () {
        }
    })
}


if($('#vacancy-list').length) {
    Vue.config.devtools = true;
    Vue.component('vacancyList', require('./components/vacancyList.vue').default)
    
    const routes = [
    ]
    
    const router = new VueRouter({
        routes,
        mode: 'history'
    })

    const vacancyListApp = new Vue({
        el: '#vacancy-list',
        name: 'vacancyList',    
        router,
        mounted () {
        }
    })
}

