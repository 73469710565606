var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.filter == "true" || _vm.filter
      ? _c("div", [
          _c("h3", [_vm._v("Filter by")]),
          _vm._v(" "),
          _c("div", { staticClass: "row pt-6" }, [
            _c("div", { staticClass: "col-xs-12 col-md-4" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.level,
                      expression: "filters.level"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.filters,
                        "level",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "", selected: "selected" } }, [
                    _vm._v("All levels")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("Level 1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("Level 2")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("Level 3")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("Level 4")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "5" } }, [_vm._v("Level 5")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "6" } }, [_vm._v("Level 6")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "7" } }, [_vm._v("Level 7")])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 col-md-4" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.type,
                      expression: "filters.type"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.filters,
                        "type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "", selected: "selected" } }, [
                    _vm._v("All types")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "apprenticeships" } }, [
                    _vm._v("Apprenticeships")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "learner-loans" } }, [
                    _vm._v("Learner Loans")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "short-courses" } }, [
                    _vm._v("Short Courses")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "traineeships" } }, [
                    _vm._v("Traineeships")
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 col-md-4" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.industry,
                      expression: "filters.industry"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.filters,
                        "industry",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "", selected: "selected" } }, [
                    _vm._v("All Industries")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.industries, function(industry, index) {
                    return _c(
                      "option",
                      {
                        key: index,
                        domProps: {
                          value: industry.id,
                          innerHTML: _vm._s(industry.name)
                        }
                      },
                      [_vm._v(_vm._s(industry.name))]
                    )
                  })
                ],
                2
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticStyle: { "overflow-x": "scroll" } }, [
      _c(
        "table",
        { staticClass: "course-list" },
        [
          _vm._m(0),
          _vm._v(" "),
          Object.keys(_vm.courses).length == 0 || _vm.loadingState
            ? _c("tbody", [
                _vm.loadingState
                  ? _c(
                      "tr",
                      { key: -2, staticClass: "course-list__item-single" },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                Object.keys(_vm.courses).length == 0 &&
                _vm.loadingState == false
                  ? _c(
                      "tr",
                      { key: -1, staticClass: "course-list__item-single" },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td")
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "transition-group",
            {
              tag: "tbody",
              staticClass: "course-list_list",
              attrs: { name: "rowtrans" }
            },
            _vm._l(_vm.courses, function(course, index) {
              return _c(
                "tr",
                {
                  key: index,
                  staticClass: "course-list__item",
                  attrs: { "data-index": index }
                },
                [
                  _c("td", [
                    _c("div", [
                      _c(
                        "strong",
                        { domProps: { textContent: _vm._s(course.title) } },
                        [_vm._v("Course Title")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("label", [_vm._v("Level: ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { domProps: { textContent: _vm._s(course.level) } },
                      [_vm._v("Level")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("label", [_vm._v("Type: ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { domProps: { textContent: _vm._s(course.type) } },
                      [_vm._v("Type")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("label", [_vm._v("Industry: ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { domProps: { textContent: _vm._s(course.industry) } },
                      [_vm._v("Digital")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "button button--type-primary",
                          attrs: { href: course.permalink }
                        },
                        [
                          _vm._v(
                            "\n                            View Course\n                        "
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "course-list__headers" }, [
        _c("th", { attrs: { colspan: "1" } }, [
          _c("h4", [_vm._v("Course Title")])
        ]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "1" } }, [_c("h4", [_vm._v("Level")])]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "1" } }, [
          _c("h4", [_vm._v("Course Type")])
        ]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "1" } }, [_c("h4", [_vm._v("Industry")])]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", { staticClass: "course-list__match-height" }, [
        _vm._v("Loading courses")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", { staticClass: "course-list__match-height" }, [
        _vm._v("No courses found")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }